define("ui/helpers/linkify", ["exports", "linkify-string", "shared/helpers/linkify"], function (_exports, _linkifyString, _linkify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _linkify.default;
    }
  });
});